var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-overview" },
    [
      _c("div"),
      _c(
        "div",
        { staticClass: "refresh-box" },
        [
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.dataDialogVisible = true
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-question",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("统计说明")
            ]
          ),
          _c("div", { staticStyle: { margin: "0 20px" } }, [
            _vm._v("更新时间：" + _vm._s(_vm.time))
          ]),
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.refresh()
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-refresh",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("刷新")
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("房源新增统计")]),
          _c("div", { staticClass: "count-content2" }, [
            _c("div", { staticClass: "module-count room1" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.listingTotalCount))
                ]),
                _c("div", [_vm._v("本月新增：" + _vm._s(_vm.listingCount))]),
                _c("div", [_vm._v("房源")])
              ]),
              _c("img", {
                staticStyle: {
                  width: "54px",
                  height: "41px",
                  "margin-top": "35px"
                },
                attrs: { src: require("../../assets/fangyuan.png") }
              })
            ]),
            _c("div", { staticClass: "module-count room2" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.buildingTotalCount))
                ]),
                _c("div", [_vm._v("本月新增：" + _vm._s(_vm.buildingCount))]),
                _c("div", [_vm._v("楼栋")])
              ]),
              _c("img", {
                staticStyle: {
                  width: "42px",
                  height: "42px",
                  "margin-top": "35px"
                },
                attrs: { src: require("../../assets/loudong.png") }
              })
            ]),
            _c("div", { staticClass: "module-count room3" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.roomTotalCount))
                ]),
                _c("div", [_vm._v("本月新增：" + _vm._s(_vm.roomCount))]),
                _c("div", [_vm._v("房间")])
              ]),
              _c("img", {
                staticStyle: {
                  width: "40px",
                  height: "47px",
                  "margin-top": "35px"
                },
                attrs: { src: require("../../assets/fangjian.png") }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("房源类型统计")]),
          _c("div", { staticClass: "count-content2" }, [
            _c("div", { staticClass: "module-count room4" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.mixedRoomCount))
                ]),
                _c("div", [_vm._v("占比" + _vm._s(_vm.mixedPercent) + "%")]),
                _c("div", [_vm._v("房间（公寓/酒店民宿）")])
              ]),
              _c("img", {
                staticStyle: {
                  width: "47px",
                  height: "43px",
                  "margin-top": "35px"
                },
                attrs: { src: require("../../assets/gongyu.png") }
              })
            ]),
            _c("div", { staticClass: "module-count room5" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.schoolRoomCount))
                ]),
                _c("div", [_vm._v("占比" + _vm._s(_vm.schoolPercent) + "%")]),
                _c("div", [_vm._v("房间（学校）")])
              ]),
              _c("img", {
                staticStyle: {
                  width: "35px",
                  height: "45px",
                  "margin-top": "35px"
                },
                attrs: { src: require("../../assets/xuexiao.png") }
              })
            ]),
            _c("div", { staticClass: "module-count room6" }, [
              _c("div", { staticStyle: { "text-align": "left" } }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.industrialRoomCount))
                ]),
                _c("div", [
                  _vm._v("占比" + _vm._s(_vm.industrialPercent) + "%")
                ]),
                _c("div", [_vm._v("房间（工业园）")])
              ]),
              _c("img", {
                staticStyle: {
                  width: "43px",
                  height: "43px",
                  "margin-top": "35px"
                },
                attrs: { src: require("../../assets/gongyeyuan.png") }
              })
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("房间统计")]),
          _c("div", { staticClass: "content-second" }, [
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.livingRoomCount))
                ]),
                _c("div", [
                  _vm._v(
                    "在住房间数(总)入住率：" + _vm._s(_vm.livingPercent) + "%"
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.unLivingRoomCount))
                ]),
                _c("div", [
                  _vm._v(
                    "未入住房间数(总)空置率：" +
                      _vm._s(_vm.unLivingPercent) +
                      "%"
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.bindRoomCount))
                ]),
                _c("div", [_vm._v("绑锁房间(总)")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.unBindRoomCount))
                ]),
                _c("div", [_vm._v("未绑锁房间(总)")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.schoolLivingCount))
                ]),
                _c("div", [_vm._v("在住房间（学校）")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.industrialLivingCount))
                ]),
                _c("div", [_vm._v("在住房间(工业园)")])
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticClass: "data-num" }, [
                _c("div", { staticStyle: { "font-size": "30px" } }, [
                  _vm._v(_vm._s(_vm.mixedLivingCount))
                ]),
                _c("div", [_vm._v("在住房间（酒店）")])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "module" }, [
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [_vm._v("房源分布城市Top10")]),
            _c("div", {
              staticClass: "module-content",
              style: { width: "784px", height: "322px" },
              attrs: { id: "room-echart" }
            })
          ]
        ),
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [_vm._v("房源类型占比分析")]),
            _c("div", {
              staticClass: "module-content",
              style: { width: "784px", height: "400px" },
              attrs: { id: "total-orders-chart" }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("房东房源排名")]),
          _c(
            "div",
            { staticClass: "table-content" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "90%" },
                  attrs: { data: _vm.roomLandlordVos, height: "300" }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "Top", align: "left" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realName",
                      label: "房东姓名",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "phoneNum",
                      label: "手机号码",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "city", label: "城市", align: "left" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "roomCount",
                      label: "房间总数",
                      align: "left"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("最新房源动态")]),
          _c(
            "div",
            { staticClass: "table-content" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    },
                    {
                      name: "el-table-infinite-scroll",
                      rawName: "v-el-table-infinite-scroll",
                      value: _vm.loadMore,
                      expression: "loadMore"
                    }
                  ],
                  staticStyle: { width: "90%", overflow: "auto" },
                  attrs: {
                    data: _vm.tableData,
                    height: "300",
                    "element-loading-text": "Loading"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "createdDate",
                      label: "日期",
                      width: "190",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realName",
                      label: "房东姓名",
                      width: "120",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "customerLog", label: "动态", align: "left" }
                  }),
                  _c("div", {
                    staticClass: "empty",
                    attrs: { slot: "empty" },
                    slot: "empty"
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        padding: "10px",
                        "font-size": "14px",
                        color: "#ccc"
                      },
                      attrs: { slot: "append" },
                      slot: "append"
                    },
                    [
                      _vm.busy
                        ? _c("p", [
                            _c("i", {
                              staticClass: "el-icon-loading",
                              staticStyle: { "margin-right": "10px" }
                            }),
                            _vm._v("加载中···")
                          ])
                        : _vm._e(),
                      _vm.noMore ? _c("div", [_vm._v("没有更多了")]) : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "统计说明",
            top: "2vh",
            visible: _vm.dataDialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dataDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { "line-height": "25px", "margin-top": "-20px" } },
              [
                _c("div", { staticClass: "data-title" }, [
                  _vm._v("房源新增统计")
                ]),
                _vm._v(
                  "\n                房源=平台房东发布的房源总数；本月新增房源=本月新增加的房源总数；"
                ),
                _c("br"),
                _vm._v(
                  "\n                楼栋=所有房源下楼栋总数；本月新增楼栋=本月新增加的楼栋总数；"
                ),
                _c("br"),
                _vm._v(
                  "\n                房间=所有房源下发布的房间总数；（含已绑锁、未绑锁）本月新增房间=本月新添加的房间总数；"
                ),
                _c("br")
              ]
            ),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("房源类型统计")
              ]),
              _vm._v(
                "\n                房间（公寓/酒店/民宿）=房源类型为公寓、酒店、民宿的所有房间；占比=公寓/酒店/民宿房间总数/所有房源类型的房间总数*100%；"
              ),
              _c("br"),
              _vm._v(
                "\n                房间（学校）=房源类型为学校的所有房间；占比=学校类型的房间/所有房源类型的房间*100%；"
              ),
              _c("br"),
              _vm._v(
                "\n                房间（工业园）=房源类型为工业园的所有房间；占比=工业园类型的房间/所有房源类型的房间*100%；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("房间统计")]),
              _vm._v(
                "\n                在住房间数（总）=所有类型的房间，租约状态为在租、退房中等状态的，均统计为在租房间；"
              ),
              _c("br"),
              _vm._v(
                "\n                入住率=在住房间数/总房间数（已绑锁的所有房间）*100%；"
              ),
              _c("br"),
              _vm._v(
                "\n                未入住房间数（总）=所有类型的房间，已绑锁未出租或已绑锁已下架的状态，统计为未入住房间；"
              ),
              _c("br"),
              _vm._v(
                "\n                空置率=未入住房间/总房间数（已绑锁的所有房间）*100%；"
              ),
              _c("br"),
              _vm._v(
                "\n                绑锁房间（总）=当前已绑定门锁的房间，仅针对平台锁；"
              ),
              _c("br"),
              _vm._v(
                "\n                未绑锁房间（总）=当前已发布房间，但是未绑锁（含曾经绑锁当前已解绑锁）；"
              ),
              _c("br"),
              _vm._v(
                "\n                在住房间（学校）=学校类型的房源，租约状态为在住、退房中状态；"
              ),
              _c("br"),
              _vm._v(
                "\n                在住房间（工业园）=工业园类型的房源，租约状态为在住、退房中状态；"
              ),
              _c("br"),
              _vm._v(
                "\n                在住房间（酒店）=酒店类型的房源，租约状态为在住、退房中状态；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("房源分布城市Top10")
              ]),
              _vm._v(
                "\n                按照城市统计各个城市的房源分布，并按照从多至少展示前10个城市的房源总数情况，超过10个展示为其他；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("房源类型占比分析")
              ]),
              _vm._v(
                "\n                按照房源类型统计各种类型的房源总数，用饼状图展示房源类型的占比情况；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("房东房源排名")
              ]),
              _vm._v(
                "\n                按照房东，统计房东名下的房间总数，排名从多到少依次排序；"
              ),
              _c("br"),
              _vm._v(
                "\n                可手动滚动列表分页加载展示所有房东名下的房间总数；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("最新房源动态")
              ]),
              _vm._v(
                "\n                展示房东发布房源、房型、房间的操作动态；"
              ),
              _c("br"),
              _vm._v(
                "\n                包括：新增、编辑、删除、下架、重新发布等操作类型；"
              ),
              _c("br"),
              _vm._v(
                "\n                列表按照时间倒叙展示动态，最新动态展示在最上方；"
              ),
              _c("br")
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }