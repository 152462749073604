<template>
    <div class="data-overview">
        <div></div>
        <div class="refresh-box">
            <el-button type="text" class="refresh-btn" @click="dataDialogVisible=true"><i class="el-icon-question" style="margin-right:2px"></i>统计说明</el-button>
            <div style="margin:0 20px">更新时间：{{time}}</div>
            <el-button type="text" class="refresh-btn" @click="refresh()"><i class="el-icon-refresh" style="margin-right:2px"></i>刷新</el-button>
        </div>
        <div class="module">
            <div class="module-content">
                <div class="title">房源新增统计</div>
                <div class="count-content2">
                    <div class="module-count room1">
                        <div style="text-align:left;">
                            <div style="font-size:30px;">{{listingTotalCount}}</div>
                            <div>本月新增：{{listingCount}}</div>
                            <div>房源</div>
                        </div>
                        <img style="width:54px;height:41px;margin-top:35px;" src="../../assets/fangyuan.png" />
                    </div>
                    <div class="module-count room2">
                        <div style="text-align:left;">
                            <div style="font-size:30px;">{{buildingTotalCount}}</div>
                            <div>本月新增：{{buildingCount}}</div>
                            <div>楼栋</div>
                        </div>
                        <img style="width:42px;height:42px;margin-top:35px;" src="../../assets/loudong.png" />
                    </div>
                    <div class="module-count room3">
                        <div style="text-align:left;">
                            <div style="font-size:30px;">{{roomTotalCount}}</div>
                            <div>本月新增：{{roomCount}}</div>
                            <div>房间</div>
                        </div>
                        <img style="width:40px;height:47px;margin-top:35px;" src="../../assets/fangjian.png" />
                    </div>
                </div>
            </div>
            <div class="module-content">
                <div class="title">房源类型统计</div>
                <div class="count-content2">
                    <div class="module-count room4">
                        <div style="text-align:left;">
                            <div style="font-size:30px;">{{mixedRoomCount}}</div>
                            <div>占比{{mixedPercent}}%</div>
                            <div>房间（公寓/酒店民宿）</div>
                        </div>
                        <img style="width:47px;height:43px;margin-top:35px;" src="../../assets/gongyu.png" />
                    </div>
                    <div class="module-count room5">
                        <div style="text-align:left;">
                            <div style="font-size:30px;">{{schoolRoomCount}}</div>
                            <div>占比{{schoolPercent}}%</div>
                            <div>房间（学校）</div>
                        </div>
                        <img style="width:35px;height:45px;margin-top:35px;" src="../../assets/xuexiao.png" />
                    </div>
                    <div class="module-count room6">
                        <div style="text-align:left;">
                            <div style="font-size:30px;">{{industrialRoomCount}}</div>
                            <div>占比{{industrialPercent}}%</div>
                            <div>房间（工业园）</div>
                        </div>
                        <img style="width:43px;height:43px;margin-top:35px;" src="../../assets/gongyeyuan.png" />
                    </div>
                </div>
            </div>
        </div>
        <div class="module">
            <div class="module-content">
                <div class="title">房间统计</div>
                <div class="content-second">
                    <div class="module-box">
                        <div class="data-num">
                            <div style="font-size:30px;">{{livingRoomCount}}</div>
                            <div>在住房间数(总)入住率：{{livingPercent}}%</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <div class="data-num">
                            <div style="font-size:30px;">{{unLivingRoomCount}}</div>
                            <div>未入住房间数(总)空置率：{{unLivingPercent}}%</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <div class="data-num">
                            <div style="font-size:30px;">{{bindRoomCount}}</div>
                            <div>绑锁房间(总)</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <div class="data-num">
                            <div style="font-size:30px;">{{unBindRoomCount}}</div>
                            <div>未绑锁房间(总)</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <div class="data-num">
                            <div style="font-size:30px;">{{schoolLivingCount}}</div>
                            <div>在住房间（学校）</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <div class="data-num">
                            <div style="font-size:30px;">{{industrialLivingCount}}</div>
                            <div>在住房间(工业园)</div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="module-box">
                        <div class="data-num">
                            <div style="font-size:30px;">{{mixedLivingCount}}</div>
                            <div>在住房间（酒店）</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module">
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">房源分布城市Top10</div>
                <div id="room-echart" class="module-content" :style="{ width: '784px', height: '322px'}"></div> 
            </div>
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">房源类型占比分析</div>
                <div id="total-orders-chart" class="module-content" :style="{ width: '784px', height: '400px'}"></div>  
            </div>
        </div>
        <div class="module">
            <div class="module-content">
                <div class="title">房东房源排名</div>
                <div class="table-content">
                    <el-table :data="roomLandlordVos" style="width: 90%;" height="300">
                        <el-table-column type="index" label="Top" align="left"></el-table-column>
                        <el-table-column prop="realName" label="房东姓名" align="left"></el-table-column>
                        <el-table-column prop="phoneNum" label="手机号码" align="left"></el-table-column>
                        <el-table-column prop="city" label="城市" align="left"></el-table-column>
                        <el-table-column prop="roomCount" label="房间总数" align="left"></el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="module-content">
                <div class="title">最新房源动态</div>
                <div class="table-content">
                    <el-table :data="tableData" style="width: 90%;overflow:auto" height="300"
                    v-loading="listLoading" element-loading-text="Loading" v-el-table-infinite-scroll="loadMore">
                        <el-table-column prop="createdDate" label="日期" width="190" align="left"></el-table-column>
                        <el-table-column prop="realName" label="房东姓名" width="120" align="left"></el-table-column>
                        <el-table-column prop="customerLog" label="动态" align="left"></el-table-column>
                        <div slot="empty" class="empty"/>
                        <div slot="append" style="text-align: center;padding:10px;font-size: 14px;color: #ccc;">
                            <p v-if="busy"><i class="el-icon-loading" style="margin-right: 10px;" />加载中···</p>
                            <div v-if="noMore">没有更多了</div>
                        </div>
                    </el-table>
                </div>
            </div> 
        </div>
        <el-dialog title="统计说明" top="2vh" :visible.sync="dataDialogVisible"  :close-on-click-modal="false">
            <div>
                <div style="line-height:25px;margin-top:-20px">
                    <div class="data-title">房源新增统计</div>
                    房源=平台房东发布的房源总数；本月新增房源=本月新增加的房源总数；<br>
                    楼栋=所有房源下楼栋总数；本月新增楼栋=本月新增加的楼栋总数；<br>
                    房间=所有房源下发布的房间总数；（含已绑锁、未绑锁）本月新增房间=本月新添加的房间总数；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">房源类型统计</div>
                    房间（公寓/酒店/民宿）=房源类型为公寓、酒店、民宿的所有房间；占比=公寓/酒店/民宿房间总数/所有房源类型的房间总数*100%；<br>
                    房间（学校）=房源类型为学校的所有房间；占比=学校类型的房间/所有房源类型的房间*100%；<br>
                    房间（工业园）=房源类型为工业园的所有房间；占比=工业园类型的房间/所有房源类型的房间*100%；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">房间统计</div>
                    在住房间数（总）=所有类型的房间，租约状态为在租、退房中等状态的，均统计为在租房间；<br>
                    入住率=在住房间数/总房间数（已绑锁的所有房间）*100%；<br>
                    未入住房间数（总）=所有类型的房间，已绑锁未出租或已绑锁已下架的状态，统计为未入住房间；<br>
                    空置率=未入住房间/总房间数（已绑锁的所有房间）*100%；<br>
                    绑锁房间（总）=当前已绑定门锁的房间，仅针对平台锁；<br>
                    未绑锁房间（总）=当前已发布房间，但是未绑锁（含曾经绑锁当前已解绑锁）；<br>
                    在住房间（学校）=学校类型的房源，租约状态为在住、退房中状态；<br>
                    在住房间（工业园）=工业园类型的房源，租约状态为在住、退房中状态；<br>
                    在住房间（酒店）=酒店类型的房源，租约状态为在住、退房中状态；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">房源分布城市Top10</div>
                    按照城市统计各个城市的房源分布，并按照从多至少展示前10个城市的房源总数情况，超过10个展示为其他；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">房源类型占比分析</div>
                    按照房源类型统计各种类型的房源总数，用饼状图展示房源类型的占比情况；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">房东房源排名</div>
                    按照房东，统计房东名下的房间总数，排名从多到少依次排序；<br>
                    可手动滚动列表分页加载展示所有房东名下的房间总数；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">最新房源动态</div>
                    展示房东发布房源、房型、房间的操作动态；<br>
                    包括：新增、编辑、删除、下架、重新发布等操作类型；<br>
                    列表按照时间倒叙展示动态，最新动态展示在最上方；<br>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import elTableInfiniteScroll from 'el-table-infinite-scroll';
export default {
    directives: {
        'el-table-infinite-scroll': elTableInfiniteScroll
    },
    data() {
        return {
            time: '',
            listingCount: '',
            listingTotalCount: '',
            buildingTotalCount: '',
            roomTotalCount: '',
            buildingCount: '',
            roomCount: '',
            mixedRoomCount: '',
            mixedPercent: '',
            schoolRoomCount: '',
            schoolPercent: '',
            industrialRoomCount: '',
            industrialPercent: '',
            livingRoomCount: '',
            livingPercent: '',
            unLivingRoomCount: '',
            unLivingPercent: '',
            bindRoomCount: '',
            unBindRoomCount: '',
            mixedLivingCount: '',
            schoolLivingCount: '',
            industrialLivingCount: "",
            pieData: [],
            dataDialogVisible: false,
            roomLandlordVos:[],
            count:2,
            tableData: [],
            listLoading: true,
            isFirst: true,
            pageSize: 20,
            pageNum: 1,
            total:0,
            busy:false,
            noMore:false,
            cityName: [],
            cityData: [],
        }
    },
    activated() {
        this.time = this.getNowTime();
        this.getData();
        this.tableData = [];
        this.isFirst = true;
        this.listLoading = true;
        this.pageSize = 20;
        this.pageNum = 1;
        this.total = 0,
        this.busy = false;
        this.noMore = false;
        this.getList();
    },
    mounted() {
    },
    methods: {
        getData(){
            this.post("statistic-service/statistic/getApartmentStatistics").then(res=>{
                if(res){
                    this.listingCount = res.listingCount!=null?res.listingCount:'--';
                    this.listingTotalCount = res.listingTotalCount!=null?res.listingTotalCount:'--';
                    this.buildingTotalCount = res.buildingTotalCount!=null?res.buildingTotalCount:'--';
                    this.roomTotalCount = res.roomTotalCount!=null?res.roomTotalCount:'--';
                    this.buildingCount = res.buildingCount!=null?res.buildingCount:'--';
                    this.roomCount = res.roomCount!=null?res.roomCount:'--';
                    this.mixedRoomCount = res.mixedRoomCount!=null?res.mixedRoomCount:'--';
                    this.mixedPercent = res.mixedPercent!=null?res.mixedPercent:'--';
                    this.schoolRoomCount = res.schoolRoomCount!=null?res.schoolRoomCount:'--';
                    this.schoolPercent = res.schoolPercent!=null?res.schoolPercent:'--';
                    this.industrialRoomCount = res.industrialRoomCount!=null?res.industrialRoomCount:'--';
                    this.industrialPercent = res.industrialPercent!=null?res.industrialPercent:'--';
                    this.livingRoomCount = res.livingRoomCount!=null?res.livingRoomCount:'--';
                    this.livingPercent = res.livingPercent!=null?(res.livingPercent*100).toFixed(2):'--';
                    this.unLivingRoomCount = res.unLivingRoomCount!=null?res.unLivingRoomCount:'--';
                    this.unLivingPercent = res.unLivingPercent!=null?(res.unLivingPercent*100).toFixed(2):'--';
                    this.bindRoomCount = res.bindRoomCount!=null?res.bindRoomCount:'--';
                    this.unBindRoomCount = res.unBindRoomCount!=null?res.unBindRoomCount:'--';
                    this.mixedLivingCount = res.mixedLivingCount!=null?res.mixedLivingCount:'--';
                    this.schoolLivingCount = res.schoolLivingCount!=null?res.schoolLivingCount:'--';
                    this.industrialLivingCount = res.industrialLivingCount!=null?res.industrialLivingCount:'--';
                    this.roomLandlordVos = res.roomLandlordVos!=null?res.roomLandlordVos:[];
                    let roomTypeData = [];
                    res.roomTypeVos.forEach(item =>{
                        roomTypeData.push({
                            value:item.roomCount,
                            name:item.type
                        })
                    });
                    this.pieData = roomTypeData;
                    this.creatEchart();
                     // 处理城市排名
                     let nameData = [];
                    let numberData = [];
                    res.roomCityVoList.forEach(item =>{
                        nameData.push(item.city);
                        numberData.push(item.roomCount)
                    });
                    this.cityName = nameData
                    this.cityData = numberData;
                    this.creatRoom()
                }
            })
        },
        loadMore() {
            if (this.busy || this.total < 20) return //每页展示100条，总数小于100或加载中时不加载
            const total = this.pageSize * this.pageNum
            if (this.total < total) { // 总数小于已请求页数时不加载，提示noMore'没有更多了'
                this.noMore = true
                return
            }
            this.busy = true
            this.pageNum += 1
            this.getList()
        },
        getList(){
            this.listLoading = true;
            let dto = {
                pageSize: this.pageSize,
                pageNum: this.pageNum
            }
            this.post("statistic-service/roomLog/all",dto,{
                isUseResponse: true,
            }).then(res=>{
                if(res.data.code == 0){
                    this.tableData = this.tableData.concat(res.data.list);
                    this.total = this.tableData.length
                    this.listLoading = false;
                    this.busy = false
                }
            })
        },
        getNowTime(){
            var date = new Date();
            var sign2 = ":";
            var year = date.getFullYear() // 年
            var month = date.getMonth() + 1; // 月
            var day = date.getDate(); // 日
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds() //秒
            var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
            var week = weekArr[date.getDay()];
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            return year + "/" + month + "/" + day + " " + hour + sign2 + minutes + sign2 + seconds;
        },
        refresh(){
            this.time = this.getNowTime();
            this.getData();
            this.tableData = [];
            this.isFirst = true;
            this.listLoading = true;
            this.pageSize = 20;
            this.pageNum = 1;
            this.total = 0,
            this.busy = false;
            this.noMore = false;
            this.getList();
        },
        creatEchart(){
            const chartDom = document.getElementById('total-orders-chart')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    itemWidth: 12,   // 设置图例图形的宽
                    itemHeight: 12,  // 设置图例图形的高
                },
                // color: ['#629AFF', '#F49A7E', '#9C8EFE', '#69CABE', '#FEE355'],
                series: [
                    {
                        name: '房源类型',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        data: this.pieData,
                        emphasis: {
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        creatRoom(){
            const chartDom = document.getElementById('room-echart')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                //图表位置
                grid: {
                    left: "3%",
                    // right: "4%",
                    bottom: "3%",
                    top:'15px',
                    containLabel: true,
                },
                //X轴
                xAxis: {
                    type: "value",
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //不显示X轴刻度线和数字
                    splitLine: { show: false },
                    axisLabel: { show: false },
                },
                yAxis: {
                    type: "category",
                    data: this.cityName,
                    //升序
                    inverse: true,
                    splitLine: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //key和图间距
                    offset: 10,
                    //key文字大小
                    nameTextStyle: {
                        fontSize: 16,
                    },
                },
                color: ['#76DC71'],
                series: [
                {
                    //柱状图自动排序，排序自动让Y轴名字跟着数据动
                    realtimeSort: false,
                    name: "数量",
                    type: "bar",
                    data: this.cityData,
                    barWidth: 12,
                    barGap: 20,
                    smooth: false,
                    valueAnimation: false,
                    //Y轴数字显示部分
                    label: {
                        normal: {
                            show: true,
                            position: "right",
                            valueAnimation: false,
                            offset: [5, 2],
                            textStyle: {
                                color: "#333",
                                fontSize: 14,
                            },
                        },
                    },
                    itemStyle: {
                        emphasis: {
                            barBorderRadius: 7,
                        },
                        //颜色样式部分
                        normal: {
                            barBorderRadius: 7,
                        },
                    },
                },
                ],
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        }
    }
}
</script>
<style lang="scss" scope>
.data-overview{
    background: #F6F6F6;
    margin: -10px -20px;
    padding: 35px;
    font-size: 14px;
    overflow: auto;
    .module{
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;
    }
    .refresh-box{
        height: 16px;
        line-height: 16px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        margin-right: 20px;
    }
    .refresh-btn{
        color: #333;
        padding: 0;
        margin-left: 3px;
    }
    .title{
        border-left: 4px solid #30BAC1;
        height: 16px;
        line-height: 16px;
        padding-left: 7px;
        background: #FFFFFF;
        font-size: 16px;
    }
    .content{
        background: #FFFFFF;
        width: 794px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
    }
    .content-second{
        background: #FFFFFF;
        width: 1608px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
        text-align: center;
    }
    .line{
        background: #DCDFE6;
        width: 1px;
        height: 93px;
    }
    .module-box{
        display: flex; 
        img{
            width: 56px;
            height: 56px;
            margin-right: 15px;
        }
    }
    .circleBox {
        position: relative;
        text-align: center;
        width: 200px;
    }

    .circleCenter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .module-content {
        padding: 10px 0 0 10px;
        background: #FFFFFF;
    }
    .data-title {
        font-weight: bold;
        margin: 10px 0 0;
    }
    .el-table__row>td{ border: none; }
    .el-table th.is-leaf { border-bottom: none; }
    .el-table::before { height: 0px; } 
    .table-content{
        overflow: auto;
        background: #FFFFFF;
        width: 794px;
        margin: 10px 0 30px;
        height: '400px';
    }
    .count-content2{
        background: #FFFFFF;
        width: 794px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
        text-align: center;
    }
    .module-count{
        color: #fff;
        width: 210px;
        height: 82px;
        padding: 10px 15px;
        border-radius: 10px;
        display: flex; 
        justify-content: space-between;
    }
    .room1{
        background-image: linear-gradient(to right , #5671FC, #C97DFE);
    }
    .room2{
        background-image: linear-gradient(to right , #38A8F6, #767CFC);
    }
    .room3{
        background-image: linear-gradient(to right , #F6950B, #FFEB3E);
    }
    .room4{
        background-image: linear-gradient(to right , #E95E74, #F09F61);
    }
    .room5{
        background-image: linear-gradient(to right , #38A8F6, #767CFC);
    }
    .room6{
        background-image: linear-gradient(to right , #48D898, #9EE04E);
    }
}
</style>